import React, { useState } from 'react'
import * as BsIcons from 'react-icons/bs'
import useWindowDimensions from '../Tools/WindowDimensions'


function InfoBox() {
    const [infoBoxMode, setInfoBoxMode] = useState('')
    const {width} = useWindowDimensions()
    

  return (

   
    <div className="relative w-screen h-screen z-10">  

      
      {infoBoxMode === '' ?

      <>
      <div className='h-full w-screen flex'>
          <div className='m-auto px-8'>

              {width > 800 ?
  
              <div className='flex justify-center items-center tablet:text-6xl text-center text-white px-8'>
                  <h2>WE</h2>
       <div className='text-red-600 tablet:text-3xl animate-ping text-center px-8 pb-4'><BsIcons.BsFillHeartFill size={60}/></div>
       <h2>CODE</h2>
         
</div>

            
        :
  
            <div className='flex pt-16 text-3xl pb-8 text-white m-auto justify-center items-center text-center'>
                <h2>WE</h2>
     <div className='text-red-600 tablet:text-3xl animate-ping text-center px-8 pb-1'><BsIcons.BsFillHeartFill size={25}/></div>
     <h2>CODE</h2>
       
</div>

        
        }
            <p className='text-white text-center tablet:text-2xl'>We hate wishy washy business speak...so...listen up, here's what we do in plain English...</p>
            <p className='text-white text-center pt-4'>We build websites for small businesses and start-ups in <span style={{fontFamily:'wordpress', fontSize:22}}>WordPress</span> and/or <span className='font-bold'style={{fontFamily:'system-ui', fontSize:22}}>ReactJS</span>.</p>
            <p className='text-white text-center'>We build apps in <span className='font-bold'style={{fontFamily:'system-ui', fontSize:22}}>React Native</span> with <span className='font-bold'style={{fontFamily:'firebase', fontSize:22}}>Firebase</span>, Tailwind and TypeScript.</p>
            <p className='text-white text-center pt-4'>We turn <span className='font-bold text-xl uppercase animate-bounce'>ideas</span> into <span className='text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text uppercase text-xl animate-pulse font-bold'>reality</span></p>
{/* 
        <p className='text-white text-center pt-8'>Tell us about you and we'll tell you how we help.</p>

{width > 600 ? 
<div className='text-white flex justify-evenly pt-8'>
    <p className='hover:text-transparent text-center bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text font-bold' onClick={() => setInfoBoxMode('smallBiz')}>I'm a small business owner</p>
    <p className='hover:text-transparent text-center bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text font-bold' onClick={() => setInfoBoxMode('startup')}>I have an entrepreneurial idea</p>
</div>
:

<div className='text-white pt-8'>
    <p className='hover:text-transparent text-center bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text font-bold pt-4' onClick={() => setInfoBoxMode('smallBiz')}>I'm a small business owner</p>
    <p className='hover:text-transparent text-center bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text font-bold pt-4' onClick={() => setInfoBoxMode('startup')}>I have an entrepreneurial idea</p>
</div>
} */}
        </div>
  </div>
      </>
: 

infoBoxMode === 'smallBiz' ?

<>
<div className='text-white flex items-center' onClick={() =>  setInfoBoxMode('')}>
<BsIcons.BsChevronLeft size={25} className='m-4 text-white'/>
<p>Go Back</p>
    </div>
<div className='h-full flex'>
          <div className='m-auto'>
<div className='flex w-fit m-auto text-white justify-center items-center text-3xl p-8 text-center'>
                  <h2>HELLO BOSS!</h2>
                  </div>
                  <div className='text-white text-center p-2'>
                  <p>We believe in giving small businesses the tools to grow. 
                  </p>
                  <p className='pt-2'>Just because you don't have millions in budget doesn't mean you can't look the part on the big web stage!
                  </p>
                  <p className='pt-8'>Here's how we have helped small businesses like yours: 
                  </p>

                  <div className='text-black grid grid-cols-3 pt-8'>
                      <div className='bg-white mx-4 my-1 rounded-xl p-8'>
                          <h2>WEBSITES</h2>
                          <p>We can build you a WordPress site and look after it on your behalf.</p>
                          <p>We can keep it updated 24/7 and we're only a text or call away.</p>
                          <p>We can stick to your existing brand or suggest ways to freshen up your look..</p>
                      </div>
                      <div className='bg-white mx-4 my-1 rounded-xl p-8'>
                          <h2>APPS</h2>
                          <p>We've built apps for many Salons, Restaurants and Coffee Shops already.</p>
                          <p>Our work is specific to each brand and can be highly customised or built from scratch.</p>
                          <p>We incorporate games, loyalty cards, push notifications and much much more..</p>
                      </div>
                      <div className='bg-white mx-4 my-1 rounded-xl p-8'>
                          <h2>TECH HEADACHES SOLVED</h2>
                          <p>Have an issue. Something broken?</p>
                          <p>We are your external tech department and we're here for you 24/7.</p>
            
                      </div>
                     
                  </div>
                  </div>
                  </div>
                  </div>
</>

:

infoBoxMode === 'startup' ?

<>
    <div className='text-white flex items-center' onClick={() =>  setInfoBoxMode('')}>
<BsIcons.BsChevronLeft size={25} className='m-4 text-white'/>
<p>Go Back</p>
    </div>
<div className='h-full flex'>
          <div className='m-auto'>
<div className='flex w-fit m-auto text-white justify-center items-center text-3xl p-8 text-center'>
                  <h2>STARTUP MODE ACTIVATED</h2>
                  </div>
                  <div className='text-white text-center p-2'>
                  <p>Let's go! So you have a great idea but you have scambled around amongst your network and no-one has the brains to put it together. 
                  </p>
                  <p className='pt-2'>You may have even started looking at big app building companies who want to charge you thousands to get the MVP (minimum viable product) together for you.
                  </p>
                  <p className='pt-8'>Here's how we have helped new CEO's on their first steps towards a healthy thriving company. 
                  </p>

                  <div className='text-black grid grid-cols-3 pt-8'>
                      <div className='bg-white mx-4 my-1 rounded-xl p-8'>
                          <h2>WEB APPS</h2>
                          <p>We build stable, user friendly, modern web apps in ReactJS.</p>
                          <p>We advise on what features should be working and what can be placeholders</p>
                          <p>We help with branding and stick to a plan with you for how your idea should look on the web.</p>
                      </div>
                      <div className='bg-white mx-4 my-1 rounded-xl p-8'>
                          <h2>APPS</h2>
                          <p>We develop mobile apps in React Native.</p>
                          <p>This means they can run on iOS and Android.</p>
                          <p>We help with branding and stick to a plan with you for how your idea should look on the web.</p>
                      </div>
                      <div className='bg-white mx-4 my-1 rounded-xl p-8'>
                          <h2>CTO HOURS</h2>
                          <p>We pitch in.</p>
                          <p>We can act as your CTO until you find the right internal team for your idea.</p>
                          <p></p>
                      </div>
                     
                  </div>
                  </div>
                  </div>
                  </div>
</>

:

null

}
    </div>




  )
}

export default InfoBox