import React from 'react'

function LetsGo() {
  return (
    <>
 <div className='p-8 absolute bg-white w-screen'>
<h2 className='text-center uppercase text-2xl'>Okay...let's just skip to the important stuff:</h2>
<div>
    <h2 className='text-xl text-center uppercase pt-4 pb-4'>What are we building?</h2>
    <div className='grid grid-cols-3 text-center'>

        <div className='border rounded-xl m-2 p-4'>
            <h2>AN APP</h2>
        </div>
        <div className='border rounded-xl m-2 p-4'>
            <h2>
             A WEBSITE
            </h2>
        </div>

        <div className='border rounded-xl m-2 p-4'>
            <h2>
             SOMETHING ELSE...
            </h2>
        </div>



    </div>
</div>


 </div>
    </>
  )
}

export default LetsGo