import React from 'react'
import logo1 from '../Assets/Images/clientLogos/logo1.png'
import logo2 from '../Assets/Images/clientLogos/logo2.png'
import logo3 from '../Assets/Images/clientLogos/logo3.png'
import logo4 from '../Assets/Images/clientLogos/logo4.png'
import logo5 from '../Assets/Images/clientLogos/logo5.png'
import logo6 from '../Assets/Images/clientLogos/logo6.png'

function Clients() {

    const clientList = [
        {
            img:logo2,
            client:'Naked Hare',
            link: 'https://www.nakedharegroup.com',
            alt:'Naked Hare logo'
        },
        {
            img:logo1,
            client:'Black Burgers',
            link: 'https://www.blacksburgers.co.uk',
            alt:'Blacks logo'
        },
        {
            img:logo6,
            client:'Wefumo',
            link: 'http://www.wefumo.co.uk/',
            alt:'Wefumo logo'
        },
        {
            img:logo3,
            client:'Gauntlett',
            link: 'http://www.gauntlettboxingclub.co.uk/',
            alt:'Gauntlett logo'
        },
        {
            img:logo4,
            client:'Blink Beauty',
            link: 'https://www.blinklashesnbrows.com/',
            alt:'Blink Beauty logo'
        },
        {
            img:logo5,
            client:'Bauer Templeton',
            link: 'https://www.bauertempleton.co.uk/',
            alt:'Bauer Templeton logo'
        },
    ]
  return (
    <div className='relative z-10 bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen h-full flex p-16'>
        <div className='m-auto'>
        <h2 className='text-white text-center text-2xl'>SOME OF OUR HAPPY CLIENTS</h2>
        <div className='grid grid-cols-2 tablet:grid-cols-6 items-center'>

{clientList.map(client => (
      <div className='flex justify-center m-4 p-4'>
        <a href={client.link} target="_blank" rel="noreferrer">
      <img src={client.img} className='h-24 object-contain hover:scale-125 duration-300' alt={client.alt}/>
        </a>
      </div>
))}

        </div>
        </div>
    </div>
  )
}

export default Clients