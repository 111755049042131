import React from 'react'
import nhWeb from '../Assets/Images/slides/mockNH.png'
import nhApp from '../Assets/Images/slides/phone3.png'
import Footer from '../components/Footer/Footer'

function Promotion() {
  return (
    <>
    <div className='absolute z-10'>
    <div className='bg-white w-screen pt-32 pb-16 px-4'>
        <h2 className='text-center text-4xl'>IT'S HOT AS BALLS!</h2>
        <p className='text-center pb-8'>...so we're running a crazy special offer!</p>
        <p className='text-center'>Between now and the 31st August we are offering all small businesses a phenomenal deal.</p>
        <p className='text-center'>Get a fully built custom website (that means lightening quick load speeds) and loyalty app for iOS and Android.</p>
        <p className='text-center'>We're also giving you 2 months free of the monthly cost</p>


<div className='tablet:flex justify-evenly pt-8 text-center'>

    <div className='pb-8'>
    <h2 className='uppercase text-2xl'>Custom Website</h2>
    <div className='flex justify-center'>
    <img src={nhWeb} className='h-64 py-4' alt='NH website'/>
    </div>

    <p>100% custom coded</p>
    <p>Log in for all users</p>
    <p>Fully hosted</p>
    <p>Lightening quick</p>
    <p>Admin area to edit app settings and vouchers</p>
    </div>
 
    <div>
    <h2 className='uppercase text-2xl'>Loyalty App</h2>
    <div className='flex justify-center'>
    <img src={nhApp} className='h-64 py-4' alt='NH App'/>
    </div>

    <p>Allow users to collect loyalty points or stamps</p>
    <p>Send push notifications to your users</p>
    <p>Fully hosted</p>
    <p>iOS and Android supported</p>
    <p>Custom vouchers</p>
    </div>
   

<div className='pt-4'>
<h2 className='text-center text-2xl text-red-600 uppercase'>USUAL COST:</h2>
<h2><strike>£1899 + £179 monthly</strike></h2>
<h2 className='text-center text-2xl text-red-600 uppercase pt-8'>Promotion COST:</h2>
<h2>£799 + £129 monthly (after 2 months free)</h2>
<div>

<div className='flex justify-center pt-8'>
<a href='https://wa.me/447850153604'>
<button title='text' className='border border-black border-2 px-4 py-1 hover:bg-black hover:text-white m-1'>
    <h2 className='uppercase pt-1'>Click here to Whatsapp us now</h2>
</button>
</a>
</div>

<div className='flex justify-center pt-2'>
<a href='tel:07850153604'>
<button title='call' className='border border-black border-2 px-4 py-1 hover:bg-black hover:text-white m-1'>
    <h2 className='uppercase pt-1'>Click here to call Sam now</h2>
</button>
</a>
</div>

</div>
</div>
</div>
<h2 className='text-xl text-center uppercase pt-8'>Just say the magic phrase:</h2>
<h2 className='text-2xl text-center uppercase'>"IT'S HOT AS BALLS OUT THERE!"</h2>
    </div>
   
   <div className='bg-black text-white py-8 px-8 text-center'>
    <h2 className='text-2xl uppercase'>What does this mean?</h2>
    <p>We will build you your own custom ecosystem</p>
    <p>Your website will be super quick to load and have everything you need from booking system integrations to contact forms.</p>
    <p className='pt-2'>Not only will your website be good but it will also prompt users to install your brand new app, allowing your customers to sign up for an account and start collecting loyalty stamps!</p>
    <p>You will be able to message all your app users on the device they use most often and run promotions and giveaways for your most loyal customers.</p>
    <p>Everything you need to get up and running is included and we will show you how to use it to it's full potential.</p>
    <p className='pt-4'>In summary: You can compete with the giants of the high street for a fraction of the cost.</p>

   </div>

   <div className='bg-white text-black py-8 px-8 text-center'>
    <h2 className='text-2xl uppercase'>Weighing it up?</h2>
    <p>We charge substantially less than most web and app development teams because we are a small, lean team and a boutique operation.</p>
    <p>Usually this level of development would cost you between £3000 and £10,000.</p>
    <p>To be frank, we are transitioning our business to work with small businesses because we believe they are best suited to having these tools at their fingertips.</p>
    <p>You won't find this sort of deal anywhere else and we reserve the right to shut it down when the work load becomes too heavy.</p>
    
   </div>
<Footer />
</div>
   
    </>
  )
}

export default Promotion