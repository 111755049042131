import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import icon from '../../Assets/Images/icon2022.png'
import useWindowDimensions from '../Tools/WindowDimensions'
import Navdata from './Navdata'
import * as AiIcons from 'react-icons/ai'
import Modal from 'react-modal';
import NavData from './Navdata'

function Headerbar() {
  const {width} = useWindowDimensions()
  const [mobNav, setMobNav] = useState(false)

  return (
    <>
    
    <div className='fixed z-50 w-screen bg-black/60 text-white flex justify-between items-center px-16 h-1/8'>
      <Link to='/'>
<div className='flex py-4 items-center transition ease-in-out delay-0 hover:-translate-y-1 hover:scale-110 duration-300'>
<img src={icon} className='h-8 mr-8' alt="TGF icon"/>
{width < 700 ? null :
<h2 className='uppercase text-2xl pt-4'>THEGEEKFLOOR</h2>
}
</div>
      </Link>


{width < 1300 ? 
<div>
  <AiIcons.AiOutlineMenu size={35} onClick={() => setMobNav(!mobNav)} className='z-20'/>
  </div>

:
<div className='flex items-center pt-2'>
    {Navdata.map(nav => (
      <Link key={nav.title} to={nav.path}>
      <div className='px-4'>
<h2 className='transition delay-0 hover:scale-150 duration-300 uppercase hover:text-transparent hover:bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>{nav.title}</h2>
        </div>
      </Link>
    ))}
</div>
  }



<Modal
        isOpen={mobNav}
        onRequestClose={() => setMobNav(false)}
        contentLabel="Mobile Navigation"
        className='h-screen w-screen absolute z-20'
      >
       <div className='z-20 bg-black h-screen w-screen p-16 pt-32'>
         {/* <div className='flex justify-end'>
           <AiIcons.AiOutlineClose  className='text-red-600' size={30} onClick={() => setMobNav(false)}/>
         </div> */}
         <div className='absolute w-fit z-20 flex flex-col justify-center text-white'>
         {Navdata.map(nav => (
      <Link key={nav.title} to={nav.path} onClick={() => setMobNav(false)}>
      <div className='pb-8'>
<h2 className='text-left transition delay-0 hover:scale-125 duration-300 uppercase hover:text-transparent hover:bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>{nav.title}</h2>
        </div>
      </Link>
    ))}
         </div>
       </div>
      </Modal>


    </div>


    <div className='overflow-hidden'>
   <div className={!mobNav ? 'fixed flex -translate-y-full h-screen transition-translate duration-700 w-screen bg-black/90 z-40' : 'fixed flex translate-y-0 duration-700 transition-translate h-screen w-screen bg-black/90 z-40'}>
    <div className='m-auto pb-16 text-center'>
    {NavData.map(nav => (
      <div className='text-white text-center uppercase p-4 text-2xl'>
        <Link to={nav.path} onClick={() => setMobNav(false)}>
        <h2 className='text-center transition delay-0 hover:scale-125 duration-300 uppercase hover:text-transparent hover:bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>{nav.title}</h2>
        </Link>
        </div>
    ))}
    <div className='flex justify-center pt-16 text-white'>
<a href='https://www.instagram.com/thegeekfloor' target="_blank" rel="noreferrer">
     <AiIcons.AiOutlineInstagram size={35} className='hover:scale-125 duration-300 mx-4'/>
</a>
     <a href='https://facebook.com/thegeekfloor/' target="_blank" rel="noreferrer">
     <AiIcons.AiOutlineFacebook size={35} className='hover:scale-125 duration-300 mx-4'/>
     </a>
      </div>
    </div>

    </div>
    </div>


</>

  )
}

export default Headerbar