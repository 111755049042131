import React from 'react'
import useWindowDimensions from '../components/Tools/WindowDimensions';
import yourbrand from '../Assets/Images/yourBrand.webp'
import Footer from '../components/Footer/Footer'

function Contact() {
    const {width} = useWindowDimensions()

  return (
    <>
    <div className='absolute z-10 text-center bg-white pt-16'>
    
    <div className='flex justify-between p-8 items-center tablet:p-16'>

{width < 700 ? null : 
<div className='w-1/3'>
    <div className="p-8">
    <img src={yourbrand} alt={'your brand icon example'} />
        </div>
    </div>
    }

    <div className='pt-8 w-full tablet:w-2/3 bg-slate-100 tablet:p-16 p-8 m-auto'>
    <h2 className='uppercase text-center tablet:text-right text-2xl border border-b-tgfpink border-4 border-slate-100 py-4'>
        LETS BE <span className='text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'> FRIENDS </span></h2>
    <div>
    <p className='text-center tablet:text-right pt-2'>"We're normal!" they cried out on their contact page!</p>
    <p className='text-center tablet:text-right pt-2'>We hate corporate nonsense, we love what we do, we do what we love and we don't beat around the bush.</p>
    <p className='text-center tablet:text-right pt-2'>We work with clients who expect a level of professionalism but want to be treated like people rather than another number.</p>
    <p className='text-center tablet:text-right pt-2'>We work WITH our clients...we'll pop in for a coffee once in a while and take stock of what you need, what you want and then we'll get to work impressing you. Let us take the tech headache away!</p>
    <p className='text-center tablet:text-right pt-2'>If that sounds like the sort of relationship you want with your geek's...we'll get on just fine! So what's stopping you, book an intro call, email us, call us...whatever it takes to get this ball rolling.</p>
    <p className='text-center tablet:text-right pt-2'>We'll see you soon!</p>
<p className='text-center tablet:text-right pt-2'>
Sam, Jenn and the TGF team

x</p>
    </div>
    </div>
    </div>
    <Footer />
    </div>


    </>
  )
}

export default Contact