import React from 'react'

function Socials() {
  return (
    <div className='text-center pt-8 pb-8 h-screen'>
    <h2 className='text-2xl'>Socials</h2>
    <p>Hate it!!!</p>
</div>
  )
}

export default Socials