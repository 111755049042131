import React from 'react'
import app from '../../Assets/Images/app.png'
import web from '../../Assets/Images/web.png'
import design from '../../Assets/Images/design.png'
import { Link } from 'react-router-dom'
import useWindowDimensions from '../Tools/WindowDimensions'

function Hero() {
    const {width} = useWindowDimensions()
  return (
      <>
      <div>

    <div className='text-white w-screen p-16 py-52 grid grid-cols-2 items-center'>
        <div className='z-10 w-fit h-fit'>
        <h2 className='text-2xl tablet:text-6xl'>
        WE BRING<br />
        YOUR TECHNOLOGY <br />
        IDEAS <br />
        <span className='text-3xl tablet:text-7xl text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>
        TO LIFE
        </span>

        </h2>
        </div>
        {width < 1000 ? null : 

        <div className='flex justify-evenly z-10 w-full h-fit'>
            <Link to='/apps'>
            <img src={app} className='h-16 transition ease-in-out delay-0 hover:scale-150 duration-300' alt="app icon"/>
            </Link>
            <Link to='/websites'>
            <img src={web} className='h-16 transition ease-in-out delay-0 hover:scale-150 duration-300' alt="web icon"/>
            </Link>
            <Link to='/design'>
            <img src={design} className='h-16 transition ease-in-out delay-0 hover:scale-150 duration-300' alt="design icon"/>
            </Link>
    
        </div>
        }

        </div>
        </div>
      </>
  )
}

export default Hero