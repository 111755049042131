import React, { useState } from 'react'
import laptop from '../Assets/Images/laptopIcon.webp'
import laptopB from '../Assets/Images/laptopBlIcon.webp'
import pen from '../Assets/Images/pen.png'
import penB from '../Assets/Images/penBlIcon.webp'
import phone from '../Assets/Images/app.png'
import phoneB from '../Assets/Images/phoneBlIcon.webp'

import blacks from '../Assets/Images/ourWork/blacks.webp'
import bauer from '../Assets/Images/ourWork/bauer.webp'
import blink from '../Assets/Images/ourWork/blink.webp'
import eriera from '../Assets/Images/ourWork/eriera.png'

import naked from '../Assets/Images/ourWork/phone3.webp'
import blinkphone from '../Assets/Images/ourWork/phone1.webp'
import maphone from '../Assets/Images/ourWork/phone2.webp'
import tblphone from '../Assets/Images/ourWork/tblphone.png'
import wefumoDesign from '../Assets/Images/ourWork/wefumoDesign.webp'
import maDesign from '../Assets/Images/ourWork/madesign.webp'
import Footer from '../components/Footer/Footer'

function OurWork() {
  const [selected, setSelected] = useState('apps')


const webWork = [
//   {
//   img: `${blacks}`,
//   link: 'https://www.blacksburgers.co.uk/',
//   title: 'BLACKS BURGERS',
//   text: 'Blacks Burgers is a restaurant with 2 locations in Epsom & Horley. They needed a stunning website to go with their frankly stunning burgers! This build linking out to a food ordering platform for pickups & deliveries and an online shop built in woocommerce with an easy flow to add items to add to your DIY burger boxes to cook at home.'
// },
{
  img: `${blink}`,
  link: 'https://www.blinklashesnbrows.com/',
  title: 'BLINK BEAUTY',
  text: 'Blink Beauty have two locations both working with TheGeekFloor. When we first started working together Blink already had good brand assets but needed modernising and a website that was on brand and was updateable easily. We went on to take over the day to day running and maintenance of the website as well as introduce a stamp card app for their clients.'
},
{
  img: `${bauer}`,
  link: 'https://www.bauertempleton.co.uk/',
  title: 'BAUER TEMPLETON',
  text: 'Bauer Templeton is a recruitment consultancy working specifically in the private banking and financial services industries. With an ageing site and little tech experience they asked us to create something modern and professional. After consulting them on brand colours and styles we were able to revamp their web presence and bring them into the 21st Century with built in CV submission and contact forms delivered straight to their inbox.'
},
{
img: `${eriera}`,
link: 'https://www.eriera.house/',
title: 'ERIERA HOUSE',
text: 'Eriera House is a large scale events company. With as impressive clientele they knew they needed a fresh modern look that reflected the level of service they provide to their clients. TheGeekFloor were able to get them set up swiftly with a Sngle Page Application with a view to expanding their web footprint over the coming months.'
},
// {
// img: `${eriera}`,
// link: 'https://www.wefumo.co.uk/',
// title: 'WEFUMO',
// text: ''
// },
]

const appWork = [
  {
  img: `${naked}`,
  link: 'https://www.nakedharegroup.com/applandingpage/',
  title: 'NAKED HARE',
  text: 'Naked Hare needed an app for loyalty, booking and engaging with their audience using Push Notifications. Our app allows users to collect loyalty points and at certain levels generates a new QR code voucher for discounts on repeated treatments.'
},
{
  img: `${tblphone}`,
  link: 'https://www.thebarbersloungeepsom.co.uk/our-app/',
  title: 'THE BARBERS LOUNGE',
  text: 'Mitchell had some great ideas and asked us to rise to the challenge. With an idea about getting his audience playing custom games to compete for free rewards we set to work on "Slicey Scissors" (a flappy bird clone but with scissors and barber poles). The game was a huge success and we set to work on the next game. Soon after "Snake at the Barbers Lounge" (an old school nokia snake clone) was released.'
},
{
  img: `${blinkphone}`,
  link: 'https://apps.apple.com/gb/app/blink-beauty/id1572434021',
  title: 'BLINK BEAUTY',
  text: 'Blink Beauty use their app to take bookings and to allow customers to use loyalty points against their services. We worked together to make the app easy for the owners to use and made sure they were well trained on what does what.'
},
// {
//   img: `${maphone}`,
//   link: 'https://linktr.ee/Masalon',
//   title: 'M.A. SALON',
//   text: 'M.A.Salon in Kingston-upon-Thames is a hot-spot for hair, specialising in colouring. M.A. had no technology to speak of other than an antiquated booking system. Struggling to build an audience of engaged clients we rolled out an app incorporating a loyalty scheme, links out to social platforms and integrated their own booking system. Since working with them, we have built a simple to use website and integrated it with a new booking system we have set-up for them that handles 90% of their marketing automatically as well as decreasing the number of people who book and don\'t show up.'
// },
]


const designWork = [
  {
  img: `${maDesign}`,
  link: 'https://www.nakedharegroup.com/applandingpage/',
  title: 'M.A. SALON',
  text: 'M.A initially started out as "Mihai Salon" branded in pink and with a fairly dated logo. When we started working with them we proposed a re-brand in classic minimalistic black and white, after a fresh start with a modern and appealing brand image we have gone from strength to strength. Since working with them on their app, we have built a simple to use website and integrated it with a new booking system we have set-up for them that handles 90% of their marketing automatically as well as decreasing the number of people who book and don\'t show up.'
},
{
  img: `${wefumoDesign}`,
  link: 'https://www.wefumo.co.uk/',
  title: 'WEFUMO',
  text: 'weFumo is a mobile catering company focused on smoke. TGF was enlisted to create the entire brand, website, systems and menu\'s. After a successful rollout and when the pandemic hit we also created an online shop with WooCommerce to allow weFumo to keep operating in a slightly different method.'
},
]

  return (

    <>
   


    <div className='absolute z-10 text-white pt-16'>
      <div className='w-fit text-4xl p-16'>
        <h2>
          HAVE A LOOK AT SOME OF 
        <span className='text-4xl uppercase text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'> OUR WORK! </span>

        </h2>
      </div>

      <div className='flex justify-evenly bg-white text-black tablet:p-16 p-4'>

      <div className={selected === 'apps' ? 'w-1/4 border text-white rounded-xl text-center justify-center items-center w-fit bg-gradient-to-r from-tgfpink to-tgfblue p-4' : 
        'w-1/4 rounded-xl text-center justify-center items-center w-fit bg-white border p-4 text-black'}
        onMouseEnter={() => setSelected('apps')}>
          {selected === 'apps' ? 
                          <div className='flex justify-center items-center'>
                          <div className='h-16 w-fit flex justify-center'>
                         <img src={phone} className='m-2 object-cover' alt={'app icon'}/>
               </div>
                         </div>
        :
        <div className='flex justify-center items-center'>
        <div className='h-16 w-fit flex justify-center'>
       <img src={phoneB} className='m-2 object-cover' alt={'app icon'}/>
</div>
       </div>
        }
        <h2 className='text-xl text-center'>
          APPS
        </h2>
        </div>

        <div className={selected === 'web' ? 'w-1/4 border text-white rounded-xl text-center justify-center items-center w-fit bg-gradient-to-r from-tgfpink to-tgfblue p-4' : 
        'w-1/4 rounded-xl text-center justify-center items-center w-fit bg-white border p-4 text-black'}
        onMouseEnter={() => setSelected('web')}>
          {selected === 'web' ? 
                                    <div className='flex justify-center items-center'>
                                    <div className='h-16 w-fit flex justify-center'>
                                   <img src={laptop} className='m-2 object-cover' alt={'website icon'}/>
                         </div>
                                   </div>
        :
        <div className='flex justify-center items-center'>
        <div className='h-16 w-fit flex justify-center'>
       <img src={laptopB} className='m-2 object-cover' alt={'website icon'}/>
</div>
       </div>
        }
        <h2 className='text-xl text-center'>
          WEB
        </h2>
        </div>

        <div className={selected === 'design' ? 'w-1/4 border text-white rounded-xl text-center justify-center items-center w-fit bg-gradient-to-r from-tgfpink to-tgfblue p-4' : 
        'w-1/4 rounded-xl text-center justify-center items-center w-fit bg-white border p-4 text-black'}
        onMouseEnter={() => setSelected('design')}>
          {selected === 'design' ? 
                    <div className='flex justify-center items-center'>
           <div className='h-16 w-fit flex justify-center'>
          <img src={pen} className='m-2 object-cover' alt={'design icon'}/>
</div>
          </div>
        :
        <div className='flex justify-center items-center'>
        <div className='h-16 w-fit flex justify-center'>
       <img src={penB} className='m-2 object-cover' alt={'design icon'}/>
</div>
       </div>
        }
        <h2 className='tablet:text-xl text-center'>DESIGN</h2>
        </div>



      </div>
        
        <div className='bg-white h-full tablet:p-16'>

{selected  === 'apps' ? 
        <div className='border rounded-xl text-black p-8 text-center'>
        <h2 className='text-3xl '>OUR APP WORK</h2>
        <div className='grid tablet:grid-cols-3'>
          {appWork.map(work => (
            <div className='border border-4 rounded-xl p-4 m-4'>
              <div className='flex justify-center'>
              <img src={work.img} className='w-1/3 rounded-t-xl' alt={'example of work'}/>
              </div>
              <h2 className='text-2xl pt-4'>{work.title}</h2>
              <p className='p-4'>{work.text}</p>
              <a href={work.link}>
              <p className='font-bold hover:text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text pb-4'>Click here to see it</p>
              </a>
              </div>
          ))}
          </div>

      </div>
        :
        
        selected  === 'web' ? 
        <div className='border rounded-xl text-black p-8 text-center'>
          <h2 className='text-3xl '>OUR WEB WORK</h2>
          <div className='grid tablet:grid-cols-3'>
            {webWork.map(work => (
              <div className='border border-4 rounded-xl m-4'>
                <img src={work.img} className='w-full rounded-t-xl' alt={'example of work'}/>
                <h2 className='text-2xl pt-4'>{work.title}</h2>
                <p className='p-4'>{work.text}</p>
                <a href={work.link}>
                <p className='font-bold hover:text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text pb-4'>Click here to see it</p>
                </a>
                </div>
            ))}
            </div>

        </div>
        :
        
        selected  === 'design' ? 
        <div className='border rounded-xl text-black p-8 text-center'>
        <h2 className='text-3xl '>OUR DESIGN WORK</h2>
        <div className='grid tablet:grid-cols-2'>
          {designWork.map(work => (
            <div className='border border-4 rounded-xl m-4'>
              <img src={work.img} className='w-full rounded-t-xl' alt={'example of work'}/>
              <h2 className='text-2xl pt-4'>{work.title}</h2>
              <p className='p-4'>{work.text}</p>
              <a href={work.link}>
              <p className='font-bold hover:text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text pb-4'>Click here to see it</p>
              </a>
              </div>
          ))}
          </div>

      </div>
        :
        
        null
      }
        </div>
      
       <Footer />
    </div>
    </>
  )
}

export default OurWork