import React from 'react'
import { Link } from 'react-router-dom'
import appPreviews from '../Assets/Images/AppPreviewsrs.png'
import Footer from '../components/Footer/Footer'

function Apps() {
  return (
    <>
    <div className='absolute z-10 text-white pt-16'>
      <div className='w-fit text-4xl p-16'>
        <h2>
          WE COULD BE SO
        <span className='text-4xl uppercase text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'> APPY </span>
        TOGETHER!
        </h2>
      </div>
        <div className='flex justify-center bg-white'>
        <div className='w-2/3 flex justify-center p-16'>
        <img src={appPreviews} alt={'app preview icon'}/>
        </div>
        </div>
        <div className='p-16 text-center'>
      
          <p>Okay, forgive us for the pun!</p>
          <p className='pt-4'>What we're trying to say is that we make great apps.</p>
          <p className='pt-4'>With a bunch of clients already using our apps, we are seeing that customer messaging, loyalty, rewards, exclusive in-app vouchers, gamification and anything else you can imagine can be better in your own branded app!</p>
          <p className='pt-4'>Supporting both iOS and Android and building everything from the ground up ...the possibilities are endless!</p>
        </div>

        <div className='grid tablet:grid-cols-2 w-screen text-center bg-gradient-to-r from-tgfgreen to-tgfblue'>
          
          <div className='p-4 pt-8 flex flex-col'>
            <h2>TEMPLATED SOLUTIONS</h2>
            <p className='pt-4'>We can quickly deploy apps for both iOS and Android for all sorts of businesses. </p>
            <p className='pt-4'>We work with Coffee Shops, Salons and Restaurants to engage their audiences on loyalty, gamification and insider offers etc.</p>
            <div className='mt-auto'>
              <Link to='/our-work'>
            <button className='border-black hover:bg-white hover:text-black rounded-xl bg-black text-white px-4 py-2 mt-4 uppercase'><h2 className='pt-1'>See some of our work</h2></button>
              </Link>
          </div>
          </div>

          <div className='p-4 pt-8 flex flex-col'>
            <h2>COMPLETELY BESPOKE</h2>
            <p className='pt-4'>What if your idea is something completely new?</p>
            <p className='pt-4'>Because we work with the latest technology we are able to build almost anything. 
            </p>
            <div className='mt-auto'>
              <Link to='/contact'>
            <button className='border-black hover:bg-white hover:text-black rounded-xl bg-black text-white px-4 py-2 mt-4 uppercase'><h2 className='pt-1'>GET IN TOUCH TODAY</h2></button>
              </Link>
            </div>
          </div>

        </div>
        <Footer />
    </div>
    </>
  )
}

export default Apps