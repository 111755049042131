import React from 'react'
import * as AiIcons from 'react-icons/ai'
import EmailForm from '../EmailForm/EmailForm'


function Footer() {
  return (
    <>
    <div className='absolute text-white w-screen'>
        <div className='tablet:grid tablet:grid-cols-2 text-center p-16'>

<div className='pb-8'>
    <div className='flex justify-center'>
    <div className='w-fit z-10'>
    <h2 className='text-2xl uppercase pb-2 text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>Contact</h2>
    </div>
    </div>


<div className='flex justify-center'>
<div className='w-fit z-10'>

    <div className='p-2 hover:text-tgfgreen'>
            <a href='mailto:info@thegeekfloor.com' target="_blank" rel="noreferrer">
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineMail size={30}/>
            <p className='p-2'>info@thegeekfloor.com</p>
            </div>
            </a>
        </div>

  <div className='p-2 hover:text-tgfgreen'>
            <a href='https://www.instagram.com/thegeekfloor' target="_blank" rel="noreferrer">
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineInstagram size={30}/>
            <p className='p-2'>@thegeekfloor</p>
            </div>
            </a>
        </div>


        <div className='p-2 hover:text-tgfgreen'>
            <a href='https://wa.me/447850153604' target="_blank" rel="noreferrer">
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineWhatsApp size={30}/>
            <p className='p-2'>WhatsApp us</p>
            </div>
            </a>
        </div>

        <div className='p-2 hover:text-tgfgreen'>
            <a href='https://calendly.com/thegeekfloor/chat-with-sam' target="_blank" rel="noreferrer">
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineCalendar size={30}/>
            <p className='p-2'>Book a call with me</p>
            </div>
            </a>
</div>
        </div>
        </div>

</div>

<div className='flex justify-center'>
<div className='w-fit'>

    <div className='flex justify-center'>
    <div className='w-fit z-10'>
    <h2 className='text-2xl uppercase pb-2 text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>Message</h2>
    </div>
    </div>
    <EmailForm />
</div>
</div>


        </div>

    <div className='relative bottom-0 w-screen z-20 p-4'>
<div className='flex justify-center text-white'>
<a href='https://www.instagram.com/thegeekfloor' target="_blank" rel="noreferrer">
     <AiIcons.AiOutlineInstagram size={35} className='hover:scale-125 duration-300 mx-4'/>
</a>
     <a href='https://facebook.com/thegeekfloor/' target="_blank" rel="noreferrer">
     <AiIcons.AiOutlineFacebook size={35} className='hover:scale-125 duration-300 mx-4'/>
     </a>
      </div>
        <p className='text-center text-white pt-4'>© {new Date().getFullYear()} | TheGeekFloor Ltd</p>
    </div>
    </div>

    </>
  )
}

export default Footer