import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import tgf from './tgf.svg'

export default function ParticleBackground() {
    const particlesInit = async (main) => {
        console.log(main);
    
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
      };
    
      return (
        <div className="">

           <Particles
          id="tsparticles"
          init={particlesInit}
    
          options={{
            "fullScreen": {
                "enable": true,
                "zIndex": 2
            },
            "particles": {
                "number": {
                  "value": 222,
                  "density": {
                    "enable": true,
                    "value_area": 962.0472365193136
                  }
                },
                "color": {
                  "value": "#33bbb7"
                },
                "shape": {
                  "type": "circle",
                  "stroke": {
                    "width": 0,
                    "color": "#00d491"
                  },
                  "polygon": {
                    "nb_sides": 9
                  },
                  "image": {
                    "src": tgf,
                    "width": 100,
                    "height": 100
                  }
                },
                "opacity": {
                  "value": 0.1,
                  "random": false,
                  "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.1,
                    "sync": false
                  }
                },
                "size": {
                  "value": 8,
                  "random": true,
                  "anim": {
                    "enable": false,
                    "speed": 0.1,
                    "size_min": 0.1,
                    "sync": false
                  }
                },
                "line_linked": {
                  "enable": false,
                  "distance": 240.5118091298284,
                  "color": "#00d491",
                  "opacity": 0.4,
                  "width": 1
                },
                "move": {
                  "enable": true,
                  "speed": 1,
                  "direction": "none",
                  "random": false,
                  "straight": false,
                  "out_mode": "bounce",
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                  }
                }
              },
              "interactivity": {
                "detect_on": "window",
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "bubble"
                  },
                  "onclick": {
                    "enable": true,
                    "mode": "repulse"
                  },
                  "resize": true
                },
                "modes": {
                  "grab": {
                    "distance": 400,
                    "line_linked": {
                      "opacity": 1
                    }
                  },
                  "bubble": {
                    "distance": 200,
                    "size": 20,
                    "duration": 5,
                    "opacity": 0.4,
                    "speed": 1
                  },
                  "repulse": {
                    "distance": 200,
                    "duration": 0.4
                  },
                  "push": {
                    "particles_nb": 4
                  },
                  "remove": {
                    "particles_nb": 2
                  }
                }
              },
            "retina_detect": true,
            "background": {
                "color": "#000",
                "image": "",
                "position": "50% 50%",
                "repeat": "no-repeat",
                "size": "cover"
            }
        }}
        />
        </div>
      );
}
