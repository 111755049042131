import React from 'react'
import web1 from '../Assets/Images/slides/MockBB.png'
import web2 from '../Assets/Images/slides/MockBarbersLounge.png'
import web3 from '../Assets/Images/slides/mockBlink.png'
import web4 from '../Assets/Images/slides/mockNH.png'
import web5 from '../Assets/Images/slides/MockWF.png'
import Footer from '../components/Footer/Footer'

function Websites() {
  return (

    <>
    <div className='absolute z-10 text-white pt-16'>
      <div className='w-fit text-4xl p-16'>
        <h2>
          WEBSITES THAT
        <span className='text-4xl uppercase text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'> WORK FOR YOU </span>
        
        </h2>
      </div>
        <div className='flex justify-center bg-white overflow-hidden'>
        <div className='w-2/3 flex justify-center p-16'>
        <img src={web1} className='w-64 p-2' alt={'Blacks Burgers website on screen'}/>
        <img src={web2} className='w-64 p-2' alt={'BarbersLounge website on screen'}/>
        <img src={web3} className='w-64 p-2' alt={'Blink Beauty Website'}/>
        <img src={web4} className='w-64 p-2' alt={'Naked Hare website'}/>
        <img src={web5} className='w-64 p-2' alt={'WeFumo website'}/>
        
        </div>
        </div>
        <div className='p-16 text-center'>
      
          <p>At TheGeekFloor we believe a website is an important "shop window" that your prospective customers often peek in before they do any business with you. It needs to be easily found, look attractive, give a clear message and drive engagement with your brand.</p>
          <p className='pt-4'>That's why we never cut corners.</p>
        </div>

        <div className='grid tablet:grid-cols-3 w-screen text-center bg-gradient-to-r from-tgfgreen to-tgfblue'>
          
          <div className='p-4 pt-8 flex flex-col'>
            <h2>BUILT</h2>
            <p className='pt-4'>We build all of our websites in either WordPress or ReactJS.

This means we are always able to find the right fit for your business and utilise our experience to make sure you get the best results.

We hold your hand every step of the way providing support where needed and making sure you get the most from your little corner of the internet.</p>
          
          </div>

          <div className='p-4 pt-8 flex flex-col'>
            <h2>MAINTAINED</h2>
            <p className='pt-4'>We keep your site updated and running.

Never worry about patches, plugins or performance. We look after everything for you and periodically check to see if we can shave off a few seconds of load time or optimise your site for flawless results every single time someone loads it.</p>
          </div>

          <div className='p-4 pt-8 flex flex-col'>
            <h2>SUPPORTED</h2>
            <p className='pt-4'>Need to change or add something? Maybe a new face has joined the team or an exciting special offer is on its way!

Drop us a line and we'll make the necessary changes. As soon as we know, your customers will know.</p>
            
          </div>

        </div>
        <Footer />
    </div>

    </>
  )
}

export default Websites