import React from 'react'
import Hero from '../components/Hero/Hero'
import 'react-slideshow-image/dist/styles.css'
import Introduction from '../components/Intro/Introduction';
import InfoBox from '../components/InfoBox/InfoBox';
import InfoBoxTwo from '../components/InfoBox/InfoBoxTwo';
import Footer from '../components/Footer/Footer';
import Clients from '../components/Clients'



function Home() {

  return (
      <>



      <Hero />
   <Introduction />

<InfoBox />
<InfoBoxTwo />
<Clients />

<Footer />



      </>
  )
}

export default Home