import React from 'react'
import wave from '../../Assets/Images/staff/fredWave.gif'
import * as AiIcons from 'react-icons/ai'
import vcard from '../../Assets/Images/staff/fredrickard.vcf'
import Footer from '../Footer/Footer'

function FredRickard() {
  return (
    <>
    <div className='absolute z-10'>
    <div className='bg-white flex items-start grid tablet:grid-cols-3 text-center pt-32'>

        <div className='p-4 flex justify-center flex-col p-8'>
            <div className='flex justify-center'>
            <div className='w-fit'>
            <h2 className='text-3xl text-center text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>HI, I'M FRED.</h2>
            </div>
            </div>
        <p>
        This is my business card. Maybe a friend gave you this link?
        </p>

        <p>
        Maybe I sent you it in a text message? Maybe we just met and I made you scan an NFC tag….in any case, it’s cooler than a paper business card, ey?
        </p>

        <div className='p-4'>
<a href={vcard}>
<button title='save my details' className='border border-black border-2 px-4 py-1 hover:bg-black hover:text-white'>
    <h2 className='uppercase pt-1'>Click here to save my details</h2>
</button>
</a>
        </div>
        </div>


        <div className='p-4 flex justify-center flex-col'>
            <div className='flex justify-center'>
            <div className='w-fit'>
            <h2 className='text-3xl text-center text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>FRED RICKARD</h2>
            </div>
            </div>
            <p>
        I do websites and apps for small businesses
        </p>

        <div className='p-2 hover:text-tgfgreen'>
            <a href='tel:447599046887'>
            <div className='flex justify-center items-center'>
            <AiIcons.AiFillPhone size={30}/>
            <p className='p-2'>+44 7599 046 887</p>
            </div>
            </a>
        </div>


        <div className='p-2 hover:text-tgfgreen'>
            <a href='https://wa.me/447599046887'>
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineWhatsApp size={30}/>
            <p className='p-2'>WhatsApp me</p>
            </div>
            </a>
        </div>


        <div className='p-2 hover:text-tgfgreen'>
            <a href='mailto:info@thegeekfloor.com'>
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineMail size={30}/>
            <p className='p-2'>Email me</p>
            </div>
            </a>
        </div>


        <div className='p-2 hover:text-tgfgreen'>
            <a href='https://calendly.com/thegeekfloor/chat-with-sam'>
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineCalendar size={30}/>
            <p className='p-2'>Book a call</p>
            </div>
            </a>
        </div>





        </div>

   
        <div className='p-4'>
            <div className='flex justify-center'>
            <img src={wave} className='h-72' alt="Fred Waving"/>
            </div>
        </div>
    </div>
<Footer />
    </div>

    </>
  )
}

export default FredRickard