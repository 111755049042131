import React from 'react'
import wave from '../../Assets/Images/staff/svlWave2022.gif'
import * as AiIcons from 'react-icons/ai'
import vcard from '../../Assets/Images/staff/samvanleer.vcf'
import Footer from '../Footer/Footer'

function SamVanLeer() {
  return (
    <>
    <div className='absolute z-10 pt-16'>
    <div className='w-fit text-4xl text-white uppercase p-16'>
        <h2>
          Hello
        <span className='text-4xl uppercase text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'> I'm Sam </span>
        
        </h2>
      </div>
    <div className='bg-white flex items-start grid tablet:grid-cols-3 text-center pt-32'>


        <div className='p-4 flex justify-center flex-col'>
            <div className='flex justify-center'>
            <div className='w-fit'>
            <h2 className='text-3xl text-center text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>KEEP IN TOUCH!</h2>
            </div>
            </div>
        <p>
        This is my business card. Maybe a friend gave you this link?
        </p>

        <p>
        Maybe I sent you it in a text message? Maybe we just met and I made you scan an NFC tag….in any case, it’s cooler than a paper business card, ey?
        </p>

        <div className='p-4'>
<a href={vcard}>
<button title='save my details' className='border border-black border-2 px-4 py-1 hover:bg-black hover:text-white'>
    <h2 className='uppercase pt-1'>Click here to save my details</h2>
</button>
</a>
        </div>
        </div>


        <div className='p-4 flex justify-center flex-col'>
            <div className='flex justify-center'>
            <div className='w-fit'>
            <h2 className='text-3xl text-center text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'>SAM VAN LEER</h2>
            </div>
            </div>
            <p>
        I do websites, apps, social media, marketing & booking systems
        </p>

        <div className='p-2 hover:text-tgfgreen'>
            <a href='tel:447850153604'>
            <div className='flex justify-center items-center'>
            <AiIcons.AiFillPhone size={30}/>
            <p className='p-2'>+44 7850 153 604</p>
            </div>
            </a>
        </div>


        <div className='p-2 hover:text-tgfgreen'>
            <a href='https://wa.me/447850153604'>
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineWhatsApp size={30}/>
            <p className='p-2'>WhatsApp me</p>
            </div>
            </a>
        </div>


        <div className='p-2 hover:text-tgfgreen'>
            <a href='mailto:sam@thegeekfloor.com'>
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineMail size={30}/>
            <p className='p-2'>Email me</p>
            </div>
            </a>
        </div>


        <div className='p-2 hover:text-tgfgreen'>
            <a href='https://calendly.com/thegeekfloor/chat-with-sam'>
            <div className='flex justify-center items-center'>
            <AiIcons.AiOutlineCalendar size={30}/>
            <p className='p-2'>Book a call with me</p>
            </div>
            </a>
        </div>





        </div>

   
        <div className='p-4'>
            <div className='flex justify-center'>
            <img src={wave} className='h-72' alt="Sam waving"/>
            </div>
        </div>
    </div>
<Footer />
    </div>

    </>
  )
}

export default SamVanLeer