import React from 'react'
import ParticleBackground from '../components/Particle/ParticleBackground'

function PersonalPlayground() {
  return (
<>
  <ParticleBackground />
 <div className='flex absolute w-screen'>
<div className='text-purple-600 text-4xl flex w-screen'>
  <div className='m-auto z-10'>
  <h2>TESTING</h2>
  </div>
</div>
 </div>

</>

  )
}

export default PersonalPlayground