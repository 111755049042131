import React from 'react'
import useWindowDimensions from '../../components/Tools/WindowDimensions';

function Introduction() {
    const {width} = useWindowDimensions()

  
  return (
    <div className='relative w-screen tablet:p-16 text-center bg-white z-20'>
    
    <div className='flex justify-between p-8'>

{width < 700 ? null : 
<div className='w-1/3'>
    <div className="p-8">
      {/* <Fade arrows={false} duration={2000}>
        {fadeImages.map((fadeImage, index) => (
            <div className="each-fade m-auto " key={index}>
              <img src={fadeImage.url} className='h-96 object-contain m-auto' />
            <h2>{fadeImage.caption}</h2>
          </div>
        ))}
      </Fade> */}
        </div>
    </div>
    }

    <div className='pt-8 w-full tablet:w-2/3 bg-slate-100 tablet:p-16 p-8 m-auto'>
    <h2 className='uppercase text-center tablet:text-right text-2xl border border-b-tgfpink border-4 border-slate-100 py-4'>
        WELCOME TO YOUR EXTERNAL TECH DEPARTMENT</h2>
    <div>
    <h2 className='uppercase text-center tablet:text-right tablet:text-3xl py-4'>
    TECH STARTUPS<br/>
SMALL BUSINESSES<br/>
WEBSITES, APPS & SOFTWARE<br/>
...GET THE 
<span className='text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'> GEEKS </span>
    WORKING ON IT!
    </h2>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Introduction