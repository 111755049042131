import React from 'react'
import design from '../Assets/Images/designExample.webp'
import Footer from '../components/Footer/Footer'

function Design() {
  return (
    <>


    <div className='absolute z-10 text-white pt-16'>
      <div className='w-fit text-4xl p-16'>
        <h2>
          BRAND DESIGN THAT
        <span className='text-4xl uppercase text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text'> POPS! </span>

        </h2>
      </div>
        <div className='flex justify-center bg-white'>
        <div className='tablet:w-1/3 flex justify-center tablet:p-16'>
        <img src={design} alt={'design icon'}/>
        </div>
        </div>
        <div className='p-16 text-center'>
      
          <p>Make it...you know...pop!</p>
          <p className='pt-4'>We actually do know what you mean!</p>
          <p className='pt-4'>Whether you know exactly what you want or you want some options to pick from...we will take the time to cater to your individual needs.</p>
          <p className='pt-4'>SuppoGreat branding need only be done once and possibly tweaked down the line over time to remain modern...let's get you started on the right track!</p>
        </div>

        <div className='grid tablet:grid-cols-3 w-screen text-center bg-gradient-to-r from-tgfgreen to-tgfblue'>
          
          <div className='p-4 pt-8 flex flex-col'>
            <h2>CONSULT</h2>
            <p className='pt-4'>We begin every design session with a good understanding of your brand. What you represent, what you believe and what you want the world to know. Every design element needs to be instantly recognisable as your brand!</p>
          </div>

          <div className='p-4 pt-8 flex flex-col'>
            <h2>CONTRIBUTE</h2>
            <p className='pt-4'>We're not doing design for us...we're doing design for you. It is this understanding that allows us to make our clients happy. While we can carefully guide you away from purple and yellow colour pairings...ultimately, you know your brand best and your vision is what we are trying to bring to life on the screens of your clients. Contribution is the key word here because we contribute as do you!</p>
           
          </div>

          <div className='p-4 pt-8 flex flex-col'>
            <h2>COMPLETE</h2>
            <p className='pt-4'>The work doesn't stop there. You'll most likely need brand assets for new products, new promotions and we are always on hand to jump in and create those for you based on our existing work on your brand. Once we have an identity, we can stick to it and create whatever you need.</p>
           
          </div>

        </div>
        <Footer />
    </div>


    </>
  )
}

export default Design