

const NavData = [
    {
        title: 'Home',
        path: '/',
        icon: '',
        cName:''
    },

    {
        title: 'Apps',
        path: '/apps',
        icon: '',
        cName:''
    },

    {
        title: 'Websites',
        path: '/websites',
        icon: '',
        cName:''
    },

    {
        title: 'Design',
        path: '/design',
        icon: '',
        cName:''
    },

    // {
    //     title: 'Socials',
    //     path: '/socials',
    //     icon: '',
    //     cName:''
    // },

    {
        title: 'Our work',
        path: '/our-work',
        icon: '',
        cName:''
    },

    {
        title: 'Contact',
        path: '/contact',
        icon: '',
        cName:''
    },


]

export default NavData