import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './screens/Home';
import Websites from './screens/Websites';
import Apps from './screens/Apps';
import Design from './screens/Design';
import Socials from './screens/Socials';
import OurWork from './screens/OurWork';
import Contact from './screens/Contact';
import SamvanLeer from './components/Staff/SamvanLeer';
import FredRickard from './components/Staff/FredRickard';
import LetsGo from './components/LetsGo/LetsGo';
import React from 'react'
import ScrollToTop from './components/Tools/ScrollToTop';
import Headerbar from './components/Navigation/Headerbar';
import PersonalPlayground from './screens/PersonalPlayground';
import Promotion from './screens/Promotion';
import ParticleBackground from './components/Particle/ParticleBackground';


function App() {
  return (
<>
<Router>

<ScrollToTop>


<Headerbar />
<ParticleBackground />
<Routes>
    <Route path="/" element={<Home />} />
    <Route path="/apps" element={<Apps />} />
    <Route path="/websites" element={<Websites />} />
    <Route path="/design" element={<Design />} />
    <Route path="/socials" element={<Socials />} />
    <Route path="/our-work" element={<OurWork />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/promotion" element={<Promotion />} />

    <Route path="/playground" element={<PersonalPlayground />} />

    <Route path="/letsgo" element={<LetsGo />} />


    <Route path="/samvanleer" element={<SamvanLeer />} />
    <Route path="/fredrickard" element={<FredRickard />} />
  </Routes>

</ScrollToTop>
  </Router>
</>
  )
}

export default App
