import React from 'react'
import * as BsIcons from 'react-icons/bs'
import useWindowDimensions from '../Tools/WindowDimensions'

function SnapCarousel(props) {
    const items = props.items
    const {width} = useWindowDimensions()


  return (
      <>
    <div className='bg-white tablet:p-16 flex snap-mandatory snap-x overflow-auto'>
        {items.map(item => (
            <div className='bg-white border rounded-xl shrink-0 snap-start p-4 m-4 w-full tablet:w-1/3'>
                <div className='flex justify-center'>
                <img src={item.img} className='h-32 p-4 object-contain' alt='Carousel'/>
                </div>
                <h2 className='uppercase'>{item.title}</h2>
                <p>{item.text}</p>

                {width < 800 ? 
                item.end ? 
                <div className='flex w-full justify-start pt-8 animate-pulse'>
                <BsIcons.BsChevronLeft className='text-slate-500'/>
                <p className='text-slate-500 text-sm'>Slide</p>
                </div>
                : 
                <div className='flex w-full justify-end pt-8 items-center animate-pulse'>
                <p className='text-slate-500 text-sm'>Slide</p>
                <BsIcons.BsChevronRight className='text-slate-500'/>
                </div>
                : null}
                </div>
        ))}
    </div>
        {width > 800 ? 
         <div className='flex w-full justify-end px-8 pb-4 items-center animate-pulse'>
         <p className='text-slate-500 text-sm'>Slide</p>
         <BsIcons.BsChevronRight className='text-slate-500'/>
         </div>
         :
         null}
         </>
  )
}

export default SnapCarousel