import React, { useState } from 'react'
import SnapCarousel from '../SnappableCarousel/SnapCarousel'
import fastWeb from '../../Assets/Images/slides/MockBB.png'
import app from '../../Assets/Images/ourWork/tblphone.png'
import design from '../../Assets/Images/designExample.webp'
import code from '../../Assets/Images/codeScreen.png'
import tgfTech from '../../Assets/Images/tgfTech.png'

function InfoBoxTwo() {
    const [info, setInfo] = useState('')


const smallBizItems = [
    {
    img: `${fastWeb}`,
    title: 'We build fast and beautiful websites',
    text: 'Your website is your shop window, draw people in with great user experience and make sure you are seen',
    end: false
},

{
    img: `${app}`,
    title: 'We build fun, functionally relevant mobile apps',
    text: 'Give your customers and clients fun and engaging content and in return they will give you a place on their most used device',
    end: false
},

{
    img: `${design}`,
    title: 'We stick to your brand or freshen it up with you',
    text: 'We understand the value of a consistent brand. We\'ll stick to your vision and suggest tweaks where we think they may be most useful',
    end: false
},

{
    img: `${code}`,
    title: 'We look after all the geeky stuff for you',
    text: 'Why try and do everything for your small business when you can get a fully fledged, outsourced department for a fraction of the cost of hiring someone?',
    end: true
},

]


const startupItems = [
    {
    img: `${tgfTech}`,
    title: 'We deliver your MVP fast',
    text: 'An MVP (Minimum Viable Product) is the first step towards building your Saas platform, App idea or Tech startup',
    end: false
},

{
    img: `${tgfTech}`,
    title: 'We work with you to stick to a pre-defined budget',
    text: 'Unlike other agencies we won\'t jump the price after we start the work. We set clear and concise timelines and deliver on time',
    end: false
},

{
    img: `${tgfTech}`,
    title: 'We charge fairly',
    text: 'Because we are a "lean" dev company we don\'t have the huge overheads other agencies and developers do',
    end: false
},

{
    img: `${tgfTech}`,
    title: 'We can act as your CTO and guide you towards funding',
    text: 'We\'re entrepreneurs too. We have also been in your shoes before, finding a technical co-founder is a nightmare that can quickly get disheartening. We can be your support until you get married up with a full time CTO.',
    end: true
},

]


  return (
      <>
      <div className='relative z-10 w-screen bg-black p-8 pt-16'>

     <div className='text-white text-center flex justify-evenly items-center'>
         <div onMouseEnter={() => setInfo('smallBiz')} >
             <h2 className={info === 'smallBiz' ? 'text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text' : 'text-white'}>SMALL BUSINESSES</h2>

         </div>
=
         <div onMouseEnter={() => setInfo('startups')}>
             <h2 className={info === 'startups' ? 'text-transparent bg-gradient-to-r from-tgfpink via-tgfblue to-tgfgreen bg-clip-text' : 'text-white'}>ENTREPRENEURS & STARTUPS</h2>
         </div>
     </div>


    {info === '' ? 
null
:

info === 'smallBiz' ? 
         <div className='bg-white rounded-xl border mt-8 text-center p-1'>
<h2 className='uppercase text-2xl pt-8'>How we help small businesses</h2>
<SnapCarousel items={smallBizItems} />


</div>
:

info === 'startups' ? 
<div className='bg-white rounded-xl border mt-8 text-center p-1'>
<h2 className='uppercase text-2xl pt-8'>How we help Entrepreneurs and startups</h2>
<SnapCarousel items={startupItems} />

</div>
: null

}
    

      </div>
      </>
  )
}

export default InfoBoxTwo   