import React, { createContext, useState } from 'react'

const UIContext = createContext()

const UIContextProvider = ({ children }) => {

  
  const [testValue, setTestValue] = useState('yep!')


  return (
    <UIContext.Provider
      value={{
        testValue: testValue,
        setTestValue: testValue => setTestValue(testValue),
      }}
    >
      {children}
    </UIContext.Provider>
  )
}

export default UIContextProvider
export { UIContext }
